import Appheroalarm from '../components/Alarmas/heroalarm';
import AppTestimonials from '../components/testimonials';
import AppBodyalarm from '../components/Alarmas/bodyalarm';
import AppContact from '../components/contact';
import React from 'react';

function Alarmas(){
    return (

        
        <div>
            <p></p>

        <Appheroalarm />
        <AppBodyalarm />
        <AppTestimonials />
        <AppContact />
        </div>
    )
}

export default Alarmas;