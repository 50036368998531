import Appheroincendios from '../components/Incendios/heroincendios';
import AppTestimonials from '../components/testimonials';
import AppBodyincendios from '../components/Incendios/bodyincendios';
import AppContact from '../components/contact';
import React from 'react';

function DeteccionIncendios(){
    return (

        
        <div>
            <p></p>

        <Appheroincendios />
        <AppBodyincendios />
        <AppTestimonials />
        <AppContact />
        </div>
    )
}

export default DeteccionIncendios;