import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/Down/alarm-3410065_1920.jpg'),
    title: 'Perfectos para tu hogar o negocio',
    description: 'Protege tu hogar las 24 horas del día con nuestros sistemas de seguridad e incendios, garantizando tranquilidad y protección para tu familia.',
    link: 'https://www.google.com'
    //alarm-3410065_1920
  },
  {
    id: 2,
    image: require('../assets/Down/camerasecurity.jpg'),
    title: 'No esperes a que suceda!',
    description: 'Desde la prevención de robos hasta la supervisión remota, nuestras cámaras de seguridad y sistemas de vigilancia ofrecen una protección completa para tu tranquilidad y seguridad.',
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require('../assets/Down/IMG_20171020_171538719.jpg'),
    title: 'La mejor solución en la palma de tu mano!',
    description: 'Ofrecemos una solución integral y profesional que combina sistemas de alarma, videovigilancia y control de accesos',
    link: 'https://www.twitter.com'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Contactanos <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;