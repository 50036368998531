import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';

function AppContact() {
  return (
    <section id="contact" className="block contact-block">
      {/* <Container fluid>
        <div className="title-holder">
          <h2>Contactanos</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className='contact-form'>
          <Row>
            <Col sm={4}>
              <Form.Control type="text" placeholder="Enter your full name" required />
            </Col>
            <Col sm={4}>
              <Form.Control type="email" placeholder="Enter your email address" required />
            </Col>
            <Col sm={4}>
              <Form.Control type="tel" placeholder="Enter your contact number" required />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control as="textarea" placeholder="Enter your contact message" required />
            </Col>
          </Row>
          <div className='btn-holder'>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container> */}
      <Container fluid>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              satscompany@sats.mx  
            </li>
            <li>
              <i className="fas fa-phone"></i>
              998-224-1769
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              Benito Juarez, Q.Roo MX.
            </li>
          </ul>
        </div>
      </Container>
      <br></br>
      <br></br>
      <div className='google-map'>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6257.020342434533!2d-86.83583363800392!3d21.1744124366004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2b05aef653db%3A0xce32b73c625fcd8a!2zQ2FuY8O6biwgUS5SLiwgTcOpeGljbw!5e0!3m2!1ses-419!2sus!4v1710734554595!5m2!1ses-419!2sus"></iframe>
      </div>
    </section>
  );
}

export default AppContact;