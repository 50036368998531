import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import img1 from '../assets/Down/IMG8.JPG';

function AppAbout() {

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <br></br>
        <div className="title-holder">
          <h2>Quienes Somos</h2>
          <div className="subtitle">Sistemas de alta Tecnologia</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>En nuestro equipo, contamos con un equipo altamente  capacitado de asesores y técnicos especializados en el diseño, instalación y puesta en marcha de sistemas de seguridad CCTV. Nos dedicamos a comprender las necesidades específicas de cada cliente, llevando a cabo una evaluación exhaustiva de los riesgos potenciales asociados con su propiedad o negocio. A partir de esta evaluación, desarrollamos soluciones personalizadas que se adaptan perfectamente a las necesidades de seguridad de nuestros clientes.</p>
            <p>Nuestra tecnología de vanguardia no solo proporciona una protección robusta, sino que también es adaptable e interactiva. Esto significa que nuestros sistemas de seguridad no solo detectan y monitorean posibles amenazas, sino que también pueden integrarse con otros dispositivos y sistemas para una respuesta más efectiva. Además, ofrecemos la capacidad de gestionar y controlar nuestros sistemas de forma remota, lo que brinda a nuestros clientes la tranquilidad de poder supervisar la seguridad de su propiedad desde cualquier lugar y en cualquier momento, a través de una interfaz sencilla y fácil de usar.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;