import AppHeroCotizacion from '../components/herocotizacion';
import AppTestimonials from '../components/testimonials';
import AppCamaras from '../components/Camaras';
import AppContact from '../components/contact';
import React from 'react';

function Camaras(){
    return (

        
        <div>
            <p></p>

        <AppHeroCotizacion />
        <AppCamaras />
        <AppTestimonials />
        <AppContact />
        </div>
    )
}

export default Camaras;