import Carousel from 'react-bootstrap/Carousel';


var heroData = [
  {
    id: 1,
    image: require('../assets/SATS/HeroCot/Camaraheader.jpg'),
    title: 'Camaras de seguridad',
    description: 'Tienes dudas? pregunta a los experos!',
    link: 'https://www.google.com'
    //alarm-3410065_1920
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Contactanos <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;