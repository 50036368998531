import AppHero from '../components/hero';
import AppAbout from '../components/about';
import AppServices from '../components/services';
import AppWorks from '../components/works';
import AppTeams from '../components/teams';
import AppTestimonials from '../components/testimonials';
import AppBlog from '../components/blog';
import AppContact from '../components/contact';
import React from 'react';

function Home(){
    return (

        <div>
            <p></p>
        {/* <AppHero /> */}
        {/* <AppAbout /> */}
        {/* <AppServices /> */}
        <AppWorks />
        {/* <AppTeams /> */}
        <AppTestimonials />
        {/* <AppBlog /> */}
        <AppContact />
        </div>
    )
}

export default Home;