import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Dahua from '../assets/Down/Dahua.png'
import Hanwha from '../assets/SATS/Hanwha.png'
import Cisco from '../assets/SATS/Cisco.png'
import Panduit from '../assets/SATS/Panduit.png'
import Unv from '../assets/SATS/unv..png'

var testimonialsData = [
  {
    id: 1,
    imagen: <img src={Dahua} width="250" height="30" className="d-inline-block align-top" alt="Logo" />
  },
  {
    id: 2,
    imagen: <img src={Hanwha} width="250" height="30" className="d-inline-block align-top" alt="Logo" />
  },
  {
    id: 3,
    imagen: <img src={Cisco} width="250" height="30" className="d-inline-block align-top" alt="Logo" />
  },
  {
    id: 4,
    imagen: <img src={Panduit} width="250" height="30" className="d-inline-block align-top" alt="Logo" />
  },
  {
    id: 5,
    imagen: <img src={Unv} width="250" height="30" className="d-inline-block align-top" alt="Logo" />
  }
]

function AppTestimonials() {
  return (
    <section id="testimonials" className="testimonials-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nuestras Marcas</h2>
        </div>
        <Carousel controls={false}>
          {
            testimonialsData.map(testimonials => {
              return (
                <Carousel.Item key={testimonials.id}>
                  <blockquote>
                    <cite>
                      <span className='imagen'>{testimonials.imagen}</span>
                    </cite>
                  </blockquote>             
                </Carousel.Item>
              );
            })
          }
        </Carousel>
      </Container>
    </section>
  );
}

export default AppTestimonials;