import AppAbout from '../components/about';
import AppServices from '../components/services';
import AppTestimonials from '../components/testimonials';
import AppBlog from '../components/blog';
import AppContact from '../components/contact';
import { Link } from 'react-router-dom';
import React from 'react';

function QuienesSomos(){
    return (

        
        <div>
            <p></p>
        <AppAbout />
        <AppServices />
        <AppTestimonials />
        <AppContact />
        </div>
    )
}

export default QuienesSomos;