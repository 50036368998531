import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const teamsData = [
  {
    id: 1,
    image: require('../../assets/Down/Alarmas/alarma6.jpg'),
    name: 'Conectividad a Internet',
    description: 'Los sistemas inteligentes están conectados a la red a través de Wi-Fi o datos móviles. Esto permite la supervisión y el control remoto desde smartphones, tabletas o computadoras mediante aplicaciones móviles específicas.'
  },
  {
    id: 2,
    image: require('../../assets/Down/Alarmas/alarma7.png'),
    name: 'Automatización',
    description: 'Se integran con otros dispositivos domésticos inteligentes, como luces, cámaras de seguridad, cerraduras electrónicas y termostatos. Pueden programarse para realizar acciones automáticas, como encender luces o activar cámaras al detectar movimiento.'
  },
  {
    id: 3,
    image: require('../../assets/Down/Alarmas/alarma8.jpg'),
    name: 'Control Remoto',
    description: 'Los usuarios pueden armar o desarmar la alarma desde cualquier lugar utilizando una app móvil, lo que brinda una mayor flexibilidad y control sobre la seguridad del hogar, incluso a distancia.'
  },
  {
    id: 4,
    image: require('../../assets/Down/Alarmas/alarma9.jpg'),
    name: 'Notificaciones en tiempo real',
    description: 'En caso de intrusión, movimiento, apertura de puertas o ventanas, o incluso la activación de detectores de humo o gas, el sistema envía notificaciones instantáneas al teléfono móvil del usuario.'
  },
  {
    id: 5,
    image: require('../../assets/Down/Alarmas/alarma10.jpg'),
    name: 'Integración con asistentes virtuales',
    description: 'Muchos sistemas son compatibles con asistentes como Alexa, Google Assistant o Siri, permitiendo el control por voz o la integración con rutinas del hogar.'
  },
  {
    id: 6,
    image: require('../../assets/Down/Alarmas/alarma11.jpg'),
    name: 'Sensores y cámaras avanzadas',
    description: 'Pueden incluir cámaras de videovigilancia con transmisión en vivo y sensores avanzados, como detectores de movimiento con IA, que pueden distinguir entre humanos y mascotas para evitar falsas alarmas.'
  },
  {
    id: 7,
    image: require('../../assets/Down/Alarmas/alarma12.jpg'),
    name: 'Respaldo en la nube',
    description: 'Los videos y datos de los eventos detectados pueden almacenarse en la nube, permitiendo revisarlos en cualquier momento y proporcionando pruebas en caso de incidentes.'
  },
  {
    id: 8,
    image: require('../../assets/Down/Alarmas/alarma13.jpg'),
    name: 'Monitoreo profesional (Opcional)',
    description: ' Algunos sistemas permiten la conexión con empresas de seguridad que brindan monitoreo profesional 24/7, aunque no es necesario, ya que muchos funcionan de manera totalmente autónoma y sin cuotas mensuales.'
  }

]

function AppBodyalarm() {
  return (
    <section id="teams" className="block teams-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Sistemas de alarmas inteligentes</h2>
          <div className="subtitle">Los sistemas de alarmas inteligentes son una evolución de los sistemas de seguridad tradicionales, integrando tecnología avanzada para ofrecer mayor control, flexibilidad y conectividad. Estos sistemas aprovechan la domótica y el Internet de las Cosas (IoT) para proporcionar una protección más eficaz y personalizada en el hogar.</div>
        </div>
        <Row>
          {
            teamsData.map(teams => {
              return (
                <Col sm={3} key={teams.id}>
                  <div className='image'>
                    <Image src={teams.image} />
                  </div>
                  <div className='content'>
                    <h3>{teams.name}</h3>
                    <span className='designation'>{teams.designation}</span>
                    <p>{teams.description}</p>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppBodyalarm;