import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const teamsData = [
  {
    id: 1,
    image: require('../assets/Down/Camaras/Bala.jpg'),
    name: 'Camara tipo Bala',
    description: 'La cámara tipo bala es conocida por su diseño cilíndrico y su capacidad para resistir condiciones climáticas adversas. Ideal para instalaciones en exteriores, proporciona una vigilancia confiable y robusta. Su diseño visible puede disuadir a posibles intrusos, brindando una sensación de seguridad adicional.'
  },
  {
    id: 2,
    image: require('../assets/Down/Camaras/Ptz.jpg'),
    name: 'Camara tipo PTZ',
    description: 'Las cámaras PTZ son altamente versátiles y permiten un control remoto completo sobre la dirección de la vista, inclinación y zoom. Esto las hace ideales para cubrir áreas extensas o en constante cambio. Con la capacidad de girar 360 grados horizontalmente, inclinarse verticalmente y hacer zoom óptico, las cámaras PTZ son una herramienta poderosa para la vigilancia dinámica.'
  },
  {
    id: 3,
    image: require('../assets/Down/Camaras/Fisheye.jpg'),
    name: 'Camara tipo Fisheye',
    description: 'Las cámaras fisheye ofrecen un campo de visión extremadamente amplio, capturando una imagen de 360 grados o casi. Esta vista panorámica inmersiva elimina los puntos ciegos, lo que las hace perfectas para monitorear espacios grandes y complejos. Además, con tecnología de descompresión de imágenes, las cámaras fisheye pueden proporcionar múltiples vistas rectangulares de una sola imagen, maximizando la cobertura de vigilancia.'
  },
  {
    id: 4,
    image: require('../assets/Down/Camaras/Domo.jpg'),
    name: 'Camara tipo Domo',
    description: 'Las cámaras domo se caracterizan por su diseño discreto y elegante, lo que las hace ideales para aplicaciones de vigilancia en interiores. Su forma de cúpula ofrece una protección adicional contra el vandalismo, al tiempo que oculta la dirección exacta de la cámara, lo que puede disuadir a posibles delincuentes. Las cámaras domo son una opción popular para entornos minoristas, comerciales y residenciales donde se valora la seguridad sin comprometer el diseño estético.'
  }
]

function AppCamaras() {
  return (
    <section id="teams" className="block teams-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Camaras de Seguridad</h2>
          <div className="subtitle">Con un sistema de circuito cerrado de televisión (CCTV), obtendrás visibilidad en todos los puntos clave de tu propiedad. Además, podrás acceder a la visualización del entorno desde tu dispositivo móvil con facilidad. Este sistema ofrece flexibilidad para integrarse con los principales sistemas de seguridad.</div>
        </div>
        <Row>
          {
            teamsData.map(teams => {
              return (
                <Col sm={3} key={teams.id}>
                  <div className='image'>
                    <Image src={teams.image} />
                  </div>
                  <div className='content'>
                    <h3>{teams.name}</h3>
                    <span className='designation'>{teams.designation}</span>
                    <p>{teams.description}</p>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppCamaras;