import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

const teamsData = [
  {
    id: 1,
    image: require('../../assets/Down/Incendios/incendio5.jpg'),
    name: 'Detectores',
    description: 'Estos son los dispositivos que se instalan en diferentes áreas del edificio para identificar signos de un posible incendio. Hay varios tipos de detectores: Fotoelectricos, ionizacion, temperatura, llamas, o de gas.'
  },
  {
    id: 2,
    image: require('../../assets/Down/Incendios/incendio3.jpg'),
    name: 'Panel de control contra incendios',
    description: 'El panel de control es el "cerebro" del sistema de detección de incendios. Está conectado a todos los detectores y dispositivos de alarma, y se encarga de supervisar constantemente el estado del sistema. Sus funciones principales del panel son: Monitoreo de los detectores, Alarma automática, Indicador de zonas, Registro de eventos, Conexión a sistemas de monitoreo remoto.'
  },
  {
    id: 3,
    image: require('../../assets/Down/Incendios/incendio4.jpg'),
    name: 'Dispositivos de alarma',
    description: 'Estos dispositivos alertan a las personas del edificio en caso de incendio mediante sonidos fuertes (sirenas) y señales visuales (luces estroboscópicas o destellantes), para que puedan evacuar de manera segura.'
  },
  {
    id: 4,
    image: require('../../assets/Down/Incendios/incendio2.jpg'),
    name: 'Estaciones manuales',
    description: 'Son dispositivos que permiten activar manualmente el sistema de alarma en caso de que alguien detecte un incendio antes que los sensores. Se colocan estratégicamente en salidas y áreas visibles del edificio..'
  }

]

function AppBodyalarm() {
  return (
    <section id="teams" className="block teams-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Sistemas de alarmas inteligentes</h2>
          <div className="subtitle">Un sistema de detección de incendios basado en detectores y un panel de control es una solución integral para la seguridad contra incendios, 
            diseñada para detectar incendios en su fase temprana y alertar a los ocupantes o servicios de emergencia. Este tipo de sistema se utiliza comúnmente en edificios 
            comerciales, industriales y residenciales para minimizar riesgos y daños por fuego.</div>
        </div>
        <Row>
          {
            teamsData.map(teams => {
              return (
                <Col sm={3} key={teams.id}>
                  <div className='image'>
                    <Image src={teams.image} />
                  </div>
                  <div className='content'>
                    <h3>{teams.name}</h3>
                    <span className='designation'>{teams.designation}</span>
                    <p>{teams.description}</p>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppBodyalarm;