import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/Down/SATS.png'
import NavDropdown from 'react-bootstrap/NavDropdown';

function AppHeader() {
  return (
    <Navbar bg="mi-color-navbar" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src={logo} width="160" height="30" className="d-inline-block align-top" alt="Logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/QuienesSomos">Quienes Somos</Nav.Link>
            <NavDropdown title="Servicios" id="nav-dropdown-black">
              <NavDropdown.Item href="/Alarmas" style={{ color: 'black' }}>Alarmas de intrusion</NavDropdown.Item>
              <NavDropdown.Item href="/DeteccionIncendios" style={{ color: 'black' }}>Deteccion de incendios</NavDropdown.Item>
              <NavDropdown.Item href="/Camaras" style={{ color: 'black' }}>Video Vigilancia (CCTV) </NavDropdown.Item>
              {/* <NavDropdown.Item href="/ControlAcceso" style={{ color: 'black' }}>Controles de acceso y acceso personal </NavDropdown.Item>
              <NavDropdown.Item href="/ControlVehicular" style={{ color: 'black' }}>Control de Acceso vehicular </NavDropdown.Item>
              <NavDropdown.Item href="/CableadoEstructurado" style={{ color: 'black' }}>Cableado estructurado </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link href="/Trabajos">Nuestros trabajos</Nav.Link>
            {/* <Nav.Link href="#teams">Equipo</Nav.Link>
            <Nav.Link href="#testimonials">Testimonios</Nav.Link> */}
            {/* <Nav.Link href="/Camaras">Servicios</Nav.Link> */}
            <Nav.Link href="/Contactanos">Contactanos</Nav.Link>
            <Nav.Link href='https://wa.me/9982241769'>WhatsApp</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;