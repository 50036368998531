import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppHeader from './components/header';
import AppFooter from './components/footer';
import Home from './pages/Home';
import QuienesSomos from './pages/QuienesSomos';
import Trabajos from './pages/Trabajos';
import Camaras from './pages/Camaras';
import Contactanos from './pages/Contactanos';
import Alarmas from './pages/Alarmas';
import DeteccionIncendios from './pages/DeteccionIncendios';

function App() {
  return (
    <div className="App">
      <header id='header'>
        <AppHeader />
      </header>
      <main>



        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/QuienesSomos' element={<QuienesSomos />} />
            <Route path='/Trabajos' element={<Trabajos />} />
            <Route path='/Camaras' element={<Camaras />} />
            <Route path='/Contactanos' element={<Contactanos />} />
            <Route path='/Alarmas' element={<Alarmas />} />
            <Route path='/DeteccionIncendios' element={<DeteccionIncendios />} />
          </Routes>
        </Router>
      </main>

      {/* <main>
        <AppHero />
        <AppAbout />
        <AppServices />
        <AppWorks />
        <AppTeams />
        <AppTestimonials />
        <AppBlog />
        <AppContact />
      </main> */}
      <footer id="footer">
        <AppFooter />
      </footer>
    </div>
  );
}

export default App;
