import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-trophy',
    title: 'Misión',
    description: 'Proporcionar asesoramiento y servicio a nuestros clientes mediante soluciones de seguridad electrónica de alta calidad y profesionalismo.'
  },
  {
    id: 2,
    icon: 'fas fa-life-ring',
    title: 'Visión',
    description: 'Ser una de las principales empresas en ofrecer soluciones, servicio de alto nivel y profesionalismo en cada una de las ciudades que nos encontremos.'
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: 'Politicas',
    description: <p>•	Priorizar la satisfacción del cliente mediante la entrega oportuna de soluciones de seguridad y mantenimiento que cumplan con sus necesidades y expectativas.   <br/>•	Mantener un alto estándar de calidad en todos nuestros productos y servicios, garantizando su eficacia y durabilidad. <br/>•	Cumplir con todas las normativas y regulaciones pertinentes en materia de seguridad y mantenimiento, asegurando la legalidad y ética en nuestras acciones</p>
  }
]

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nosotros</h2>
          <div className="subtitle">Conocenos</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;