import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


const blogData = [
  {
    id: 1,
    image: require('../assets/Down/AlarmaIntrusion.jpg'),
    title: 'Alarmas de intrusion',
    description: 'Las alarmas de intrusión son sistemas de seguridad que protegen propiedades al detectar intrusiones, emitiendo alertas audibles y visuales y notificando a sistemas de monitoreo o autoridades. Con tecnología avanzada, se adaptan a diferentes entornos y proporcionan seguridad confiable y tranquilidad a los usuarios.',
    link: '/Alarmas'
  },
  {
    id: 2,
    image: require('../assets/Down/DeteccionIncendio.jpg'),
    title: 'Detección de incendios',
    description: 'La detección de incendios es fundamental para prevenir desastres y proteger vidas y propiedades. Estos sistemas identifican la presencia de fuego, humo o altas temperaturas, activando alarmas audibles y visuales para alertar a las personas y permitir una rápida respuesta ante emergencias.',
    link: '/DeteccionIncendios'
  },
  {
    id: 3,
    image: require('../assets/Down/videovigilancia.jpg'),
    title: 'Video Vigilancia',
    description: 'La video vigilancia es esencial para la seguridad moderna, permitiendo la supervisión en tiempo real de áreas específicas mediante cámaras estratégicamente ubicadas. No solo disuade la actividad delictiva con su presencia visible, sino que también proporciona evidencia crucial para identificar infractores.',
    link: '/Camaras'
  },
  {
    id: 4,
    image: require('../assets/Down/ControlAcceso.jpg'),
    title: 'Controles de Acceso y control de acceso peatonal',
    description: 'Los controles de acceso son esenciales para gestionar la seguridad en diversos entornos, como residenciales, comerciales e industriales. Estos sistemas regulan y autorizan el ingreso a áreas restringidas mediante métodos como tarjetas, códigos PIN o biometría. Además de proteger la propiedad y a las personas, los registros de acceso son útiles para la gestión de recursos humanos y la investigación de incidentes.',
    link: '/Contactanos'
  },  {
    id: 5,
    image: require('../assets/Down/ControlVehicular.png'),
    title: 'Control de acceso Vehicular',
    description: 'Los controles de acceso vehiculares son esenciales para regular y supervisar el tráfico en áreas restringidas, como estacionamientos y complejos residenciales. Utilizando tecnologías como barreras automáticas y lectores de tarjetas RFID, garantizan que solo los vehículos autorizados accedan, brindando seguridad adicional a la propiedad y sus ocupantes. Además, pueden integrarse con otros sistemas de seguridad, como cámaras de vigilancia y alarmas, para una protección completa.',
    link: '/Contactanos'
  },
  {
    id: 6,
    image: require('../assets/Down/cableadoestructurado.jpg'),
    title: 'Cableado estructurado',
    description: 'El cableado estructurado es un sistema completo que facilita la transmisión de datos, voz y otros servicios de comunicación en edificios u organizaciones. Este enfoque organizado y estandarizado permite una infraestructura de red eficiente y adaptable a cambios tecnológicos. Con un diseño modular, es fácil de administrar y mantener, y puede admitir una variedad de aplicaciones, desde redes informáticas hasta sistemas de telefonía y video. Al proporcionar una base sólida para la conectividad, el cableado estructurado mejora la eficiencia operativa y la escalabilidad, siendo un elemento esencial en la infraestructura de comunicaciones empresariales.',
    link: '/Contactanos'
  }
]

function AppBlog() {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Servicios</h2>
          <div className="subtitle">Sistemas de Alta Tecnologia en Seguridad</div>
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        <a href={blog.link} className="btn btn-primary">Conoce más <i className="fas fa-chevron-right"></i></a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppBlog;