import AppHero from '../components/hero';
// import AppAbout from '../components/about';
// import AppServices from '../components/services';
// import AppWorks from '../components/works';
// import AppTeams from '../components/teams';
import AppTestimonials from '../components/testimonials';
import AppBlog from '../components/blog';
import AppContact from '../components/contact';
import { Link } from 'react-router-dom';
import React from 'react';

function Home(){
    return (

        <div>
        <AppHero />
        {/* <AppAbout /> */}
        {/* <AppServices /> */}
        {/* <AppWorks /> */}
        {/* <AppTeams /> */}
        <AppBlog />
        <AppTestimonials />
        <AppContact />
        </div>
    )
}

export default Home;